.inline-table-container .inline-table {
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin-top: 5px;
  font-size: 12px;
}
table td.numeric-cell {
  font-family: 'Roboto MonoVariable', 'Roboto Mono', Courier, monospace;
  font-weight: 400;
  text-align: right;
}
table td.mean-cell {
  color: rgba(0, 0, 0, 0.38);
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}
.inline-table-container {
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px -1px,
    rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px;
  display: inline-block;
  margin-right: 8px;
}
table.inline-table th.table-name-cell {
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 13px;
  letter-spacing: 0.03rem;
}
table.inline-table .right-align {
  text-align: right;
}
table.inline-table .center-align {
  text-align: center;
}
