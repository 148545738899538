.segmentation-weights-card-container {
  flex: 1 1 100%;
  justify-content: center;
  align-items: center;
  height: 80vh;
  margin: 5px;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.segmentation-explorer-container {
  display: flex;
  gap: 20px;
  max-width: 98%;
}

.segmentation-weights-card-container {
  flex: 1;
  max-height: 78vh;
  overflow-y: auto;
  max-width: 100%;
}

.segmentation-weights-search-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.filter-button-segmentation-weights {
  margin-left: 10px;
}

.segmentation-input-variables {
  flex: 1;
  max-height: 70vh;
  overflow-y: auto;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.segmentation-weights-search-group {
  flex: 1;
}

.filter-selected-inputs-by-bucket {
  margin-left: 10px;
}

.tableWeightsFixHead {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.tableWeightsFixHead tbody {
  display: block;
  width: 100%;
  overflow: auto;
  height: 60vh;
}

.tableWeightsFixHead thead tr {
  display: block;
}

th {
  background: #eee;
}

.tableWeightsFixHead th,
.tableWeightsFixHead td {
  padding: 5px 10px;
  width: 24vb;
}

.segmentation-weights {
  cursor: pointer;
}

.segmentation-weights.selected {
  background-color: #e0e0e0;
  box-shadow: inset 0 0 5px #000;
}

.weights-card-container {
  flex: 1;
  /* overflow-y: auto; */
  margin-top: 1rem;
  max-width: 98%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.weights-section {
  width: 100%;
}

.weights-list-container {
  max-height: 400px;
  overflow-y: auto;
  padding-right: 10px;
}

.weight-input-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.weight-label {
  margin-bottom: 5px;
  text-align: center;
}

.weight-input-container {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  max-width: 70%;
  gap: 10px;
}

.weight-numeric-input {
  width: 40rem;
}

.add-weight-button-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}
.weight-button {
  color: #000;
}

@media (max-width: 600px) {
  .segmentation-weights-html-table thead th,
  .segmentation-weights-html-table tbody td {
    padding: 0.3rem;
  }

  .input-name-header,
  .weight-header {
    text-align: center;
  }
}
