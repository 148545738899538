.turf-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
  margin: 20px 0;
}

.turf-table th,
.turf-table td {
  border: 1px solid #d4d4d4;
  padding: 8px 12px;
  text-align: left;
}
.turf-table th {
  font-weight: bold;
  position: sticky;
  top: 0;
  background-color: #ffffff; /* Background color to ensure the header isn't transparent */
  z-index: 1; /* Optional: Raise the z-index if headers are being obscured by other content */
}

.turf-table thead th {
  background-color: #f5f5f5;
  cursor: pointer;
}

.turf-table tbody tr {
  height: 37px;
}

.turf-table tbody tr:nth-child(even) {
  background-color: #f5f5f5;
}

.turf-table tbody tr:nth-child(even) .selected-row {
  background-color: #e3f2fd;
}

.turf-table .select-checkbox-checked,
.turf-table .select-checkbox-unchecked {
  margin: 0;
  cursor: pointer;
}

.turf-table .consider-checkbox {
  margin: 0;
  cursor: pointer;
}

.turf-button {
  width: 100%;
  border: none;
  padding: 5px;
  cursor: pointer;
  background-color: #007bff;
  color: #ffffff;
  border-radius: 3px;
  transition: background-color 0.3s ease;
}

.turf-button:hover {
  background-color: #0056b3;
}

.turf-table-scrollable-container {
  overflow-x: auto;
}

/* For "selected" column, which appears to be the second column */
.turf-table tr th:nth-child(1),
.turf-table tr td:nth-child(1) {
  width: 60px;
  text-align: center;
}
.turf-table tr th:nth-child(2),
.turf-table tr td:nth-child(2),
.turf-table tr th:nth-child(3),
.turf-table tr td:nth-child(3) {
  width: 100px;
  text-align: center;
}

.turf-table tr th:nth-child(5),
.turf-table tr td:nth-child(5),
.turf-table tr th:nth-child(6),
.turf-table tr td:nth-child(6) {
  width: 100px;
  text-align: center;
}
