.value-fieldset > * + * {
  margin-bottom: 1rem;
}
.summary + .summary {
  margin-top: 56px;
}

.summary > h4 {
  margin-block-end: 8px;
  font-size: 14px;
}

fieldset.value-fieldset .numeric-input {
  width: 85px;
}

fieldset.value-fieldset {
  min-width: 200px;
  height: 211px;
}

fieldset > legend {
  font-size: 16px;
  /* text-transform: uppercase; */
  font-weight: 600;
  /* letter-spacing: 0.05em; */
  /* font-variant: small-caps; */
  margin-bottom: 4px;
  padding: 0 6px;
}

.range-inputs {
  display: flex;
  flex-direction: column;
  margin-top: 4px;
  /* justify-content: space-between; */
  /* gap: 6px; */
  padding-left: 16px;
}
.through-icon {
  margin-left: 24px;
  margin-bottom: 10px;
  margin-top: 8px;
}

.fake-input {
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  border-radius: 2px;
  font-weight: 500;
  /* box-shadow: 0 0 0 0 rgba(45, 114, 210, 0), 0 0 0 0 rgba(45, 114, 210, 0),
    inset 0 0 0 1px rgba(17, 20, 24, 0.2), inset 0 1px 1px rgba(17, 20, 24, 0.5); */
}

select#recode-list > option {
  font-size: 13px;
}
