.formula-tag + .formula-tag,
.operator-button + .operator-button {
  margin-left: 5px;
}
#operator-table td:first-of-type,
#operator-table td:nth-of-type(3) {
  padding-right: 15px;
}

/* #operator-table td {
  min-width: 37px;
  min-height: 37px;
} */
.options-panel {
  display: flex;
}

#operators {
  padding: 12px 0;
  flex-grow: 1;
  align-self: center;
  justify-content: space-between;
  flex-direction: column;
  display: flex;
}
#operator-table button {
  color: #5f6b7c;
  font-weight: 500;
}

#formula-preview,
#operator-table button {
  font-family: 'Roboto MonoVariable', 'Roboto Mono', Courier, monospace;
}

#formula-input::placeholder {
  font-size: 11px;
}
#operator-table #_,
#_-preview {
  color: #cd4246;
}

#formula-preview {
  padding: 7px;
}

.variable-element {
  color: #4c90f0;
}

.formula-tag svg {
  width: 12px;
  height: 12px;
}
.compute-options-panel {
  display: flex;
  padding: 6px;
}

span.formula-element.variable-element + span.formula-element.function-element {
  margin-left: 0px;
}

.formula-element.non-numeric + .formula-element.non-numeric,
.formula-element.numeric + .formula-element.non-numeric,
.formula-element.non-numeric + .formula-element.numeric {
  margin-left: 4px;
}
