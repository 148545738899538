.bp4-tab-indicator-wrapper {
  height: 27px !important;
}
.tab-menu-button {
  vertical-align: unset;
  padding-right: unset;
}
.menu-item-icon {
  display: flex;
  flex-direction: column;
  height: 20px;
  width: 16px;
  justify-content: center;
  text-align: center;
}

#viz-tabs-container > div {
  height: 22px;
}

.active-submenu {
  color: #215db0;
  font-weight: 700;
}
