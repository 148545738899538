.bp4-tab-indicator-wrapper {
  height: 27px !important;
}
th.variable-name-header:hover {
  background-color: rgba(45, 113, 210, 0.07);
}
th.variable-name-header.selected {
  background-color: rgba(45, 113, 210, 0.15);
}
table#crosstab-table > thead > tr > th.variable-name-header.banner {
  text-align: right;
}

#content-container {
  overflow: auto;
}

#crosstab-table {
  font-size: 13px;
  position: relative;
  border-collapse: collapse;
}

#chart-container #crosstab-table {
  padding: 1.25rem;
}

#crosstab-table thead tr th:nth-child(even)[colspan] {
  background: #8f99a826;
}

#crosstab-table .stat-testing,
#crosstab-table #end-metrics .stat-testing {
  color: #8f99a8;
  font-weight: normal;
  text-align: right;
  font-size: 11px;
  font-family: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono',
    'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro',
    'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;
  letter-spacing: 0.025em;
  padding-top: 0;
  padding-bottom: 0;
  /* transform: translateY(-0.4rem); */
  font-weight: 500;
  /* text-shadow: 0 0 0.35px #fff, 0 0 0.75px #fff, 0 0 1.25px #fff,
    0 0 2.5px rgb(254, 255, 217), 0 0 5.5px rgb(254, 255, 217),
    0 0 6px rgb(254, 255, 217), 0 0 6.5px rgb(254, 255, 217),
    0 0 9.5px rgb(254, 255, 217); */
}
#crosstab-table thead {
  top: 0;
  position: sticky;
  background: rgba(252, 252, 252, 1);
  box-shadow: inset 0 -1px 0 0 rgb(17 20 24 / 15%);
}

#crosstab-table td,
#crosstab-table th {
  vertical-align: middle;
  cursor: default;
}

#crosstab-table.stat-testing tbody th {
  vertical-align: top;
}

.stat-testing td {
  white-space: pre;
}

#crosstab-table thead th {
  text-align: center;
  font-weight: 400;
}

#crosstab-table th,
#crosstab-table thead th {
  font-weight: 500;
  /* text-transform: uppercase;
  letter-spacing: 0.03rem; */
  color: #404854;
}

#crosstab-table tbody th,
#crosstab-table thead tr:last-child th:not(.stat-testing) {
  text-align: left;
}
#crosstab-table td {
  text-align: right;
}
.tree-icon {
  margin: 0 4px;
}

#crosstab-table th.net-cell.variable-name-header {
  text-transform: uppercase;
  letter-spacing: 0.075em;
  font-weight: 900;
  background-image: linear-gradient(130deg, #184a90, #215db0);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

table.striped tbody tr:nth-child(odd) td {
  background: rgba(143, 153, 168, 0.08);
}

#crosstab-table th#total-sample-header {
  vertical-align: bottom;
}

#crosstab-table .total {
  font-weight: 600;
}

#crosstab-table .total-column {
  border-right: 1px solid rgb(17 20 24 / 15%);
  border-left: 1px solid rgb(17 20 24 / 15%);
}

#crosstab-table .total-row {
  border-bottom: 1px solid rgb(17 20 24 / 15%);
}

table#crosstab-table > tfoot#end-metrics > tr > th,
#crosstab-table #end-metrics td {
  color: #5f6b7c;
}

#crosstab-table #end-metrics tr:first-child td,
#crosstab-table #end-metrics tr:first-child th {
  padding-top: 16px;
}

li > a.last-recent-question {
  border-bottom: 1px solid rgba(17, 20, 24, 0.15);
}
