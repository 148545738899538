.maxdiff-ui {
  height: 100%;
}

.maxdiff-tabs {
  height: 100%;
  margin-left: 10px;
  margin-right: 10px;
}

.maxdiff-tabs .bp4-tab-list {
  position: sticky;
  padding: 10;
  top: 0px;
  background-color: #fff;
}

.average-utilities-graph {
  height: 100%;
  width: 100%;
  align-items: center;
  overflow-y: auto;
}

.average-utilities-tab {
  display: flex;
  flex-flow: column;
  height: 94%;
}

.average-utilities {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.turf {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.turf-tab {
  display: flex;
  flex-flow: column;
  height: 94%;
}

.turf-bundler-tab {
  display: flex;
  height: 94%;
}

.turf-table {
  overflow-y: auto;
  border-collapse: collapse;
}

.turf-table-scrollable-container {
  height: 100%;
  overflow-y: auto;
}

.turf-header {
  display: flex;
  column-gap: 100px;
  align-items: flex-start;
  justify-content: space-between;
  top: 30px;
  position: sticky;
}

.turf-nextBestClaim {
  flex-grow: 0;
}

.turf-totals {
  margin-left: auto;
  text-align: right;
  white-space: nowrap;
  height: 100px;
}

.turf-weightSelector-popover {
  max-height: 300px;
  overflow-y: auto;
}

/* General table styling */
.turf-table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #e1e1e1;
  margin-top: 20px;
}

/* Scrollable container for the table */
.turf-table-scrollable-container {
  max-height: 600px;
  overflow-y: auto;
}

/* Icon styles */
.icon {
  margin-left: 5px;
  vertical-align: middle;
}

/* Checkbox styles */
.select-checkbox-checked,
.select-checkbox-unchecked,
.consider-checkbox {
  margin: 0;
  cursor: pointer;
}

.custom-popover {
  display: inline-block;
}

.reached {
  color: green !important;
}

.preference-profiles-tab {
  /* display: flex; */
  /* flex-flow: column; */
  height: 90%;
}
.preference-profiles {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.preference-profiles-graph {
  overflow-y: auto;
  display: inline-block;
  max-width: 100%;
  vertical-align: top;
}

.preference-profile-bar-graph {
  height: 400px;
  width: 400px;
  flex: 1 100 auto;
}

.claims-selector {
  flex-grow: 2;
  width: 40%;
}

.claims-selector-popover,
.newmaxdiff-subgroup-selector-popover,
.joint-preferences-claims-selector-popover {
  max-height: 300px;
  overflow-y: visible;
}

.joint-preferences-tab {
  display: flex;
  flex-flow: column;
  height: 90%;
}

.joint-preferences {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.joint-preferences-graph {
  align-items: center;
  flex-grow: 1;
  height: 100%;
  display: flex;
}

.joint-preferences-claims-selector {
  margin-left: 10px;
  margin-right: 10px;
}

.decision-tree-tab {
  display: flex;
  flex-flow: column;
  height: 94%;
}

.decision-tree {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.decision-tree-graph {
  height: 100%;
  width: 100%;
  align-items: center;
}

.instructions {
  font-family: Arial, sans-serif;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  color: #333;
  max-width: 800px;
  margin: 0 auto;
}

.instructions h2 {
  font-size: 24px;
  margin-top: 20px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 2px solid #ddd;
}

.instructions p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.instructions ul {
  list-style-type: disc;
  padding-left: 40px;
  margin-bottom: 20px;
}

.instructions ul li {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 10px;
}

.instructions hr {
  border: 0;
  border-top: 1px solid #ddd;
  margin: 20px 0;
}

.key {
  display: inline-block;
  padding: 2px 6px;
  margin: 2px;
  font-size: 90%;
  line-height: 1;
  color: #555;
  background-color: #f7f7f7;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2), 0 2px 0 rgba(255, 255, 255, 0.7) inset;
  transition: 0.2s;
}

.tool-list {
  list-style-type: none;
  padding: 0;
}

.tool-item {
  border: 1px solid #e5e5e5;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 5px;
  transition: box-shadow 0.3s;
}

.tool-item:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.tool-title {
  display: flex;
  alignitems: 'center';
  textdecoration: 'none';
  color: 'inherit';
}

.tool-icon {
  margin-right: 0.5rem;
  color: #007acc; /* Some arbitrary color, you can change */
}

.tool-icon:hover {
  color: #005daa;
}
