#slides-panel {
  height: fit-content;
  /* width: calc(100% + 15px); */
  width: calc(100%);
  background: rgb(237, 239, 242);
  border: 1px solid rgba(17, 20, 24, 0.2);
  /* overflow: auto; */
  display: grid;
  align-content: flex-start;
  gap: 21px;
  padding: 14px 21px 14px 11px;
  min-height: calc(100vh - 48px - 20px - 30px - 6px - 1px - 40px);
}
.slide-card {
  aspect-ratio: 1.6666666666666667;
  flex-grow: 1;
  box-sizing: border-box;
  width: 174px;
}

.slide-card.active {
  outline: 2px solid #215db0;
  /* box-shadow: 0 0 0 1px rgb(17 20 24 / 10%), 0 2px 4px rgb(17 20 24 / 20%),
    0 8px 24px rgb(17 20 24 / 20%); */
}

.slide-container {
  display: flex;
  /* margin-left: 12px; */
}
.slide-number {
  /* margin: 2px 10px 0 2px; */
  width: 21px;
  display: flex;
  padding-top: 2px;
}

#viz-tabs-container + div {
  z-index: -1;
}
