/* General styles for the component */
.segmentation-explorer-description-div {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;  
    min-height: 50vh;
    padding: 10px;
}
  
/* Styles for the General Information Card */
.segmentation-explorer-general-information-card {
    flex: 1 1 25%;
    margin: 5px;
    padding: 1rem;     
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
  
.segmentation-explorer-general-information-card h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #333;
}
  
.segmentation-explorer-general-information-card p {
    font-size: 1rem;
    margin: 5px 0;
}
  
/* Styles for the Segment Distribution Chart Card */
.segmentation-explorer-description-distribution-chart {
    flex: 1 1 65%;
    margin: 5px;
    padding: 1rem;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
  
.segmentation-explorer-description-distribution-chart h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #333;
}
  
/* Styles for the EChartsReact component */
.segmentation-explorer-description-barchart {
    height: 40vh; 
    width: 90%; 
    margin-top: 10px;
}

@media (min-width: 600px) {
    .segmentation-explorer-general-information-card {
        flex: 1 1 30%;
    }
    
    .segmentation-explorer-description-distribution-chart {
        flex: 1 1 65%;
    }
}