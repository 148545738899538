/* .module-icon-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 3fr 1fr;
  align-content: space-around;
  justify-content: center;
  width: 96px;
  height: 82px;
  padding: 82px;
  border-radius: 5%;
  border: 1px solid gainsboro;
  gap: 10px;
  animation: 3s linear 0s infinite normal forwards running oJIEb;
} */

.modules-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, 144px);
  gap: 64px 64px;
  padding: 48px;
}
.module-card {
  /* background-image: radial-gradient(
    circle at 14% 0,
    #147eb3 0,
    rgba(104, 194, 238, 0.379) 100%
  ); */
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 3fr 1fr;
  gap: 40px;
  justify-content: center;
  align-content: space-around;
  padding: 30px;
  /* padding: 82px; */
  border-radius: 5%;
  /* border: 1px solid gainsboro; */
  box-shadow: 0 0 0 1px rgb(17 20 24 / 10%), 0 2px 4px rgb(17 20 24 / 20%),
    0 8px 24px rgb(17 20 24 / 20%);
}
.module-icon-container {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}
.module-name {
  font-size: large;
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.03rem;
}

.gradient-pattern {
  animation: gradient 4s ease infinite;
  background-image: linear-gradient(135deg, #ff8a00, #e52e71);
  background-size: 400% 400%;
  height: 100%;
  width: 100%;
}

@keyframes gradient {
  0% {
    background-position: 51% 0%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 51% 0%;
  }
}

.module-icon h5 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
