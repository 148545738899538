.embed-container {
  position: relative;
  padding-bottom: 49.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100vw - 120px);
  height: calc(100vh - 175px);
}
