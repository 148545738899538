#codeframe-input::-webkit-input-placeholder::after {
  display: block;
  content: 'Line 2\n Line 3';
}
#codeframe-input {
  color: red;
}
.options-panel {
  flex-direction: column;
  align-items: center;
  column-gap: 10px;
  row-gap: 8px;
}
blockquote.comment:before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}
blockquote.comment:after {
  color: #ccc;
  content: close-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.6em;
}
blockquote.comment {
  font-size: 1.3rem;
  letter-spacing: -0.02rem;
  font-style: italic;
  background: #f9f9f9;
  /* border-left: 10px solid #ccc; */
  margin: 0 9px 0.5rem 0;
  padding: 0.5em 10px;
}
.code-tag {
  background-color: #ccc;
  margin: 2px;
}
#comment-data {
  padding-bottom: 10px;
}
#comment-codes {
  padding: 12px 32px;
}
#comment-text {
  margin-bottom: 24px;
}
fieldset + fieldset {
  margin-top: 12px;
}
.inline-editable-prompt-text {
  color: black;
  line-height: 1.4;
}
.inline-editable-prompt-text:hover {
  background-color: white;
}
.inline-editable-prompt-text span {
  color: black;
  text-decoration: underline;
  text-decoration-style: dotted;
}
.inactive .inline-editable-prompt-text span {
  color: #5f6b7c;
  text-decoration: line-through;
  text-decoration-style: solid;
}
#prompt-template {
  cursor: default;
}
#prompt-template.inactive {
  text-decoration: line-through;
  opacity: 0.5;
}
#custom-prompt-toggle {
  color: #5f6b7c;
}
#custom-prompt-toggle:hover {
  color: black;
}
