.sortable-ghost {
  color: #c5cbd3;
  opacity: 0.4;
  background-color: #e5e8eb;
  /* display: table-row; */
  color: rgb(33, 93, 176);
}
.sortable-chosen {
  /* shows briefly on click */
  /* background-color: red; */
}
.sortable-drag {
  display: table-row;
  border: 1px solid #2a77cd;
}
tbody.sortable-table > tr.sortable-selected > td {
  /* border: 1px solid white; */
  /* padding: 4px; */
  /* color: red; */
}
tbody.sortable-table tr.sortable-reordered {
  font-weight: 600;
  /* background-color: rgba(200, 118, 25, 0.1); */
  background-color: #f1eae5;
}
tbody.sortable-table tr.sortable-reordered > td {
  color: #935610;
}
tbody.sortable-table tr.sortable-selected {
  background-color: rgba(125, 188, 255, 0.6);
}
tbody.sortable-table tr.sortable-selected > td {
  color: rgb(33, 93, 176);
}

tbody.sortable-table tr.sortable-selected > td {
  color: rgb(33, 93, 176);
}
tbody.sortable-table tr.sortable-reordered > td.old-index {
  font-weight: 400;
}
