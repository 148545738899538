.surveys-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
}

.survey-card {
  position: relative;
  width: 200px;
  height: 200px;
  padding: 20px;
}

.new-survey-card {
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.survey-card ul {
  list-style: none;
  padding: 0;
}

.survey-card ul > li {
  margin: 4px 0;
}

.icon-group {
  position: absolute;
  right: 20px;
  bottom: 20px;
  display: flex;
}
td.actions-cell {
  padding: 3px;
}
td.actions-cell > span {
  display: flex;
  justify-content: flex-end;
}
table .align-right {
  text-align: right !important;
}
table .align-center {
  text-align: center !important;
}

table.sticky-header thead {
  position: sticky;
  top: 0;
  background: #fff;
  box-shadow: 0 1px 0 0 rgb(17 20 24 / 15%);
}

.icon-group .survey-icon {
  margin-right: 2px;
}

.scrollable-select-popover {
  max-height: 40vh;
  overflow: auto;
}

#app-status-menu .enabled-status-bar-element {
  color: #215db0;
  font-weight: 700;
}

#app-status-menu button {
  color: #5f6b7c;
  font-size: 12px;
  padding: 0;
}

#n-text {
  margin-left: 2px;
  font-size: 11px;
}
