.slim-navbar,
.slim-navbar > div,
.slim-navbar button {
  height: 24px;
  min-height: 24px;
}

.error-text {
  color: #cd4246;
}

.has-tooltip {
  border-bottom: 1px dotted gray;
}

.slim-navbar button {
  padding: 2px 10px;
}

.navbar-heading {
  font-weight: 600;
  font-size: 15px;
  letter-spacing: -0.009em;
}

#page-name-container {
  @media (max-width: 1165px) {
    display: none;
  }
}
#page-name {
  display: inline-block;
  max-width: 20vw;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.slim-navbar label {
  margin-bottom: 0;
}

.no-text-wrap {
  white-space: nowrap;
}

table tr.muted-row > td {
  color: #5f6b7c99;
}

html,
html body {
  font-family: Inter, -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Open Sans', 'Helvetica Neue',
    'blueprint-icons-16', sans-serif;
  font-size: 13px;
  letter-spacing: -0.0025em;
}

.flex-grid {
  display: flex;
  flex-wrap: wrap;
}

#loading-spinner {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.unstyled-link,
.unstyled-link:hover {
  text-decoration: none;
  color: inherit;
}
