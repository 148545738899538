.bp4-tab-indicator-wrapper {
  height: 27px !important;
}

.navbar-heading > a {
  color: black;
}
.navbar-heading {
  display: inline-flex;
  align-items: center;
}

#menu-survey-link {
  color: #404854;
}

#status-footer {
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100%;
}

#status-footer .bp4-navbar-group,
#status-footer {
  height: 24px;
}

#status-footer .bp4-icon {
  margin: 0 3px;
}

.new-page-icon {
  padding-bottom: 2px;
  margin-left: 4px;
}

#app-menu button {
  vertical-align: unset;
  padding-right: unset;
}

#app-menu {
  padding: 0 15px 0 8px;
}

a {
  /* display: block; */
}
