.selected-segmentation-inputs-card-container {
    flex: 1 1 100%;
    justify-content: center;
    align-items: center;
    height: 500px;
    margin: 5px;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  .selected-segmentation-inputs-search-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .selected-segmentation-inputs-search-group {
    flex: 1;
  }
  
  .copy-button-selected-segmentation-inputs {
    margin-left: 10px;
  }

.tableFixHead {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
}

.tableFixHead tbody {
    display: block;
    width: 100%;
    overflow: auto;
    height: 37vh;
}

.tableFixHead thead tr {
    display: block;
}

th {
    background: #eee;
}

.tableFixHead th,
.tableFixHead td {
    padding: 5px 10px;
    width: 55vb;
}