.segmentation-explorer-chart-card {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh; 
  margin: 1rem; 
  padding: 1rem;
  border-radius: 0.25rem; 
  box-shadow: 0 0.125rem 0.3125rem rgba(0, 0, 0, 0.1); 
}

.chart-title {
  text-align: center;
  margin-bottom: 1rem;
}

.help-popover-content {
  max-width: 50vw; /* Limit the width of the popover content to 50% of the viewport width */
  padding: 1rem; /* Add padding for better text containment */
}

.help-popover-content img {
  max-width: 100%; /* Ensure the image does not exceed the width of the popover */
  height: auto; /* Maintain the aspect ratio of the image */
  margin-top: 1rem; /* Add margin to separate the image from the text */
}

.help-drawer-content {
  padding: 1rem; /* Add padding for better text containment */
}

.help-drawer-content img {
  max-width: 100%; /* Ensure the image does not exceed the width of the drawer */
  height: auto; /* Maintain the aspect ratio of the image */
  margin-top: 1rem; /* Add margin to separate the image from the text */
}

@media (max-width: 600px) {
  .segmentation-explorer-chart-card {
      height: 40vh; 
      margin: 0.5rem;
      padding: 0.5rem;
  }
}