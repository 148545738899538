.disabled-tag {
  opacity: 30%;
}
.enabled-tag,
.disabled-tag:hover {
  opacity: 100%;
}
.tag-group {
  padding: 0 10px;
}
.tag-group button.variable-tag {
  padding: 1.25px 2.5px;
  z-index: 1;
}

.boolean-cell-container {
  color: #8f99a8;
}

.show-values-button-container,
.boolean-cell-container {
  width: 100%;
  text-align: center;
  padding: 8px;
}
.show-values-button-container .show-values-button {
  min-height: 14px;
  min-width: 16px;
  height: 16px;
  width: 24px;
}

.source-buttons-container {
  padding: 0 4px;
  display: inline-flex;
  justify-content: space-around;
  width: 100%;
}
table.popover-table td,
table.popover-table tbody th {
  border-top: 1px solid #e8e8e8;
  border-spacing: 0;
}

div.popover-table-container {
  padding: 6px;
}

div.source-button-container button.source-button {
  min-height: 16px;
}
.source-button-container {
  min-height: 14px;
  min-width: 16px;
  height: 16px;
  width: 24px;
}
table.values-table {
  font-size: 12px;
  padding: 4px;
}
table.values-table > tr > th.values-table-cell,
table.values-table > tr > td.values-table-cell {
  padding-top: 4px;
  padding-bottom: 4px;
}

.dsg-cell .dsg-input,
.dsg-cell,
.tag-tooltip {
  font-size: 11px;
  letter-spacing: 0.005em;
}
.tag-tooltip {
  padding: 0;
}

.variable-table {
  --dsg-selection-border-color: #2c72d2;
  --dsg-selection-border-width: 1px;
  --dsg-selection-background-color: rgba(45, 113, 210, 0.1);
  --dsg-scroll-shadow-color: rgba(0, 0, 0, 0);
}

div.set-question-dialog-subheader {
  font-weight: 400;
  font-size: 11px;
  /* padding-left: 4px; */
}

.row-updated .dsg-cell {
  /* Orange */
  background: #fff7e6;
}
