/* .hide-multiselect-scrollbar {
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
}
.hide-multiselect-scrollbar select {
  padding: 4px;
  margin: -5px -20px -5px -5px;
} */
select[multiple] {
  border: none;
  padding: 12px;
}
select[multiple] option:checked {
  background-color: #edeff2;
}
select[multiple]:focus option:checked {
  background: #e9f2fb linear-gradient(0deg, #e9f2fb 0%, #e9f2fb 100%);
  color: #215db0;
}

select[multiple] > option {
  font-size: 12px;
}
