#page-container,
#main {
  display: flex;
  height: 100%;
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  -webkit-box-align: stretch;
  align-items: stretch;
  padding-right: env(safe-area-inset-right, 0px);
  padding-left: env(safe-area-inset-left, 0px);
  border-top: none;
}

#page-container {
  flex-direction: column;
}

#content-container {
  position: relative;
  /* height: calc(100vh - 24px); */
  will-change: transform;
}

#sidebar {
  position: relative;
  min-width: 270px;
  width: 270px;
  max-width: 270px;
  box-sizing: border-box;
  flex-shrink: 0;
  background-color: rgb(252, 252, 252);
  display: flex;
  flex-direction: column;
  border-right: 1px solid gainsboro;
  /* padding-top: 1.25rem;
  padding-bottom: 1rem;
  padding-inline: 1rem; */
  transition: 0.5s;
  overflow: auto;
}

#sidebar nav {
  -webkit-box-flex: 1;
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex: initial;
  flex-direction: column;
}
