#crosstab-container,
#content-container {
  height: 100%;
}
#crosstab-container {
  display: grid;
  width: 100%;
  grid-template-rows: [holding-area] auto [top-variables] auto [graph-and-left-variables] 75%;
  grid-template-columns: [left-variables] auto [graph-top-variables-and-chart] 82%;
  padding: 24px 24px 36px 24px;
}
#crosstab-ui-container {
  position: relative;
  place-items: stretch;
  -webkit-box-align: stretch;
  display: flex;
  flex-shrink: initial;
  flex-basis: initial;
  flex-direction: column;
  -webkit-box-flex: 1;
  flex-grow: 1;
  height: 100%;
}
#crosstab-container [role='progressbar'] {
  height: 100%;
}

#crosstab-container > div {
  border-radius: 2px;
  margin: 5px;
  padding: 1px;
}

#aggregation-area {
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 1;
  grid-column-end: 2;
}

#holding-area {
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 2;
  grid-column-end: 3;
}

#top-variables {
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 2;
  grid-column-end: 3;
}

#left-variables {
  grid-row-start: 3;
  grid-row-end: 4;
  grid-column-start: 1;
  grid-column-end: 2;
}

#chart-container {
  grid-column-start: 2;
  grid-column-end: 3;
  overflow: auto;
  padding: 1.25rem;
}

/* #crosstab-table thead tr:last-child th {
  box-shadow: inset 0 1px 0 0 rgb(17 20 24 / 15%);
} */
#crosstab-table {
  font-size: 13px;
  position: relative;
  border-collapse: collapse;
}

#crosstab-table thead tr th:nth-child(even)[colspan] {
  background: #8f99a826;
}

#crosstab-table .stat-testing {
  color: #404854;
  font-weight: normal;
  text-align: right;
  font-size: 11px;
}

/* #crosstab-table thead tr:nth-child(odd) th:nth-child(even)[colspan] {
  background: rgba(143, 153, 168, 0.15);
}


#crosstab-table th {
  border: 1px solid gainsboro;
}

#crosstab-table thead tr:last-child th,
#crosstab-table th.variable-name-header {
  border: unset;
}

#crosstab-table thead tr:last-child {
  background: rgb(252, 252, 252);
}
*/
#crosstab-table thead {
  top: 0;
  position: sticky;
  background: rgba(252, 252, 252, 1);
  box-shadow: inset 0 -1px 0 0 rgb(17 20 24 / 15%);
}

#crosstab-table td,
#crosstab-table th {
  vertical-align: middle;
  cursor: default;
}

#crosstab-table.stat-testing tbody th {
  vertical-align: top;
}

.stat-testing td {
  white-space: pre;
}

#crosstab-table thead th {
  text-align: center;
}

#crosstab-table tbody th,
#crosstab-table thead tr:last-child th:not(.stat-testing) {
  text-align: right;
}
#crosstab-table td {
  text-align: right;
}

.variable-area {
  border: 1px dotted gainsboro;
  min-height: 32px;
}

.variable-menu {
  max-height: 85vh;
  overflow-y: auto;
}

.draggable-variable {
  margin-right: 2px;
}
