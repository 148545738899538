div#parameters input {
  width: 100px;
}

#parameters {
  padding: 20px;
  width: 200px;
  display: flex;
  flex-direction: column;
  align-self: center;
}

#preview table td {
  text-align: right;
}
